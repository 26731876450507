import React from 'react';

export default function FileAttachmentIcon() {
  return (
    <svg width="24" height="24" viewBox="-1 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7992 3.24259C16.6912 1.13458 13.5645 1.40622 11.3746 3.59615L2.53575 12.435C1.72631 13.2444 1.26254 14.2015 1.18557 15.1283C1.10946 16.0447 1.4074 16.9635 2.1822 17.7383C2.957 18.5131 3.87575 18.811 4.7922 18.7349C5.71899 18.6579 6.67606 18.1942 7.4855 17.3847L14.203 10.6672C14.7335 10.1367 15.0455 9.55355 15.116 9.01193C15.184 8.48967 15.035 7.9637 14.5566 7.48523C14.0781 7.00677 13.5521 6.85777 13.0299 6.92578C12.4883 6.99631 11.9051 7.30826 11.3746 7.83879L6.07128 13.1421C5.87602 13.3374 5.55944 13.3374 5.36418 13.1421C5.16891 12.9468 5.16892 12.6302 5.36418 12.435L10.6675 7.13168C11.3079 6.49124 12.0835 6.04058 12.9007 5.93415C13.7374 5.82521 14.5705 6.08492 15.2637 6.77813C15.9569 7.47134 16.2166 8.30442 16.1076 9.14105C16.0012 9.95832 15.5506 10.7339 14.9101 11.3743L8.1926 18.0918C7.24417 19.0403 6.07462 19.6318 4.87496 19.7315C3.66496 19.832 2.45745 19.4278 1.47509 18.4454C0.492729 17.463 0.0885105 16.2555 0.189 15.0455C0.288631 13.8459 0.880211 12.6763 1.82864 11.7279L10.6675 2.88904C13.1643 0.392265 16.929 -0.0417833 19.5063 2.53549C22.0836 5.11276 21.6495 8.87755 19.1528 11.3743L12.7888 17.7383C12.5935 17.9335 12.277 17.9335 12.0817 17.7383C11.8864 17.543 11.8864 17.2264 12.0817 17.0312L18.4457 10.6672C20.6356 8.47729 20.9072 5.35061 18.7992 3.24259Z"
        fill="#707578"
      />
    </svg>
  );
}
